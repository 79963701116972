<template>
    <b-container fluid="">
        <b-card>
            <b-card-body>
                <b-row>
                    <b-form-group class="col-6" description="Наименование">
                        <b-form-input v-model="form.name" type="text" />
                    </b-form-group>
                    <b-form-group class="col-6" description="Цех">
                        <storage-select v-model="form.storages" openDirection="buttom" />
                    </b-form-group>
                </b-row>
                <b-row>
                    <b-col cols="6">
                        <b-form-group description="Цвет">
                            <b-form-input v-model="form.color" type="color" />
                        </b-form-group>
                    </b-col>
                    <b-col cols="6">
                        <b-form-group description="Длительность по умолчанию">
                            <b-input-group append="мин">
                                <b-form-input v-model="form.default_duration" type="number" />
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="6">
                        <b-form-group description="Требуется шоковое охлаждение">
                            <b-form-select v-model="form.has_shock_chilling" :disabled="true">
                                <b-form-select-option :value="true">Да</b-form-select-option>
                                <b-form-select-option :value="false">Нет</b-form-select-option>
                            </b-form-select>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-card-body>
            <b-card-footer>
                <b-row>
                    <b-col cols="12" class="text-right">
                        <b-button variant="success" @click="update()">Сохранить</b-button>
                    </b-col>
                </b-row>
            </b-card-footer>
        </b-card>
    </b-container>
</template>

<script>
    import {OPERATIONS_ENDPOINT} from '../../utils/endpoints';
    import StorageSelect from '../../components/_common/StorageSelect';

    export default {
        name: 'Form',
        components: {
            StorageSelect,
        },
        props: {
            id: {
                type: Number,
                required: true,
            }
        },
        data() {
            return {
                form: {
                    id: null,
                    name: '',
                    storages: [],
                    default_duration: null,
                    has_shock_chilling: false,
                }
            };
        },
        methods: {
            load() {
                const params = {
                    with: [
                        'storages',
                    ]
                };

                this.$http.get(`${OPERATIONS_ENDPOINT}/${this.id}`, { params })
                    .then((response) => this.form = response.data.data)
                    .catch((error) => this.$root.responseError(error, 'Ошибка загрузки операции'));
            },
            update() {
                const formData = {
                    color: this.form.color,
                    default_duration: this.form.default_duration,
                    name: this.form.name,
                    storage_ids: this.form.storages.map((storage) => storage.id),
                }
                this.$http.put(`${OPERATIONS_ENDPOINT}/${this.id}`, formData)
                    .then(() => {
                        this.load();
                        this.$bvToast.toast('Успешно обновлено', {variant: 'success'});
                    })
                    .catch(this.$root.responseError);
            },
        },
        mounted() {
            if (this.id) {
                this.load();
            }
        }
    }
</script>
